import React, {FC} from 'react';
import {CredentialResponse, GoogleLogin} from '@react-oauth/google';
import {AuthContextType} from '../interfaces/d';

interface AuthButtonProps {
    auth: AuthContextType;
    onSuccess: (credentialResponse: CredentialResponse) => void;
    onError: () => void;
    onSignOut: () => void;
    signOutClassName: string;
}

export const AuthButton: FC<AuthButtonProps> = ({
                                                    auth,
                                                    onSuccess,
                                                    onError,
                                                    onSignOut,
                                                    signOutClassName
                                                }) => {
    if (auth.isLoggedIn) {
        return (
            <div onClick={onSignOut} className={signOutClassName}>
                Sign Out
            </div>
        );
    }

    return (
        <GoogleLogin
            shape="rectangular"
            text="signin_with"
            theme="filled_black"
            onSuccess={onSuccess}
            onError={onError}
        />
    );
};
