import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useAuth} from "../services/authService";

const SessionChecker = () => {
    const location = useLocation();
    const {userData, signOut, loading} = useAuth();

    useEffect(() => {
        if (loading) return;

        if (!userData || !userData.session || !userData.session.expire_at) {
            console.warn("No valid session data available. Logging out.");
            signOut(false);
            return;
        }

        const currentTime = Date.now();
        if (userData.session.expire_at <= currentTime) {
            console.warn("Session expired. Logging out.");
            signOut(false);
        } else {
            console.log("Session is valid until:", new Date(userData.session.expire_at).toLocaleString());
        }
    }, [location, userData, loading]);

    return null;
};

export default SessionChecker;
