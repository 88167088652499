import React from 'react';
import footerStyles from './PageFooter.module.scss';
import aiFlowStyles from './PageFooterAIFlow.module.scss';
import {Link} from "react-router-dom";
import routes from "../routes";
import {AgreementType} from "../interfaces/d";

interface PageFooterProps {
    onCookieSettingsClick: () => void;
}

const PageFooter: React.FC<PageFooterProps> = ({onCookieSettingsClick}) => {
    // Disable AI Flow Web
    // const location = useLocation();
    // const isAIFlowPage = location.pathname.startsWith(routes.AIFlow);
    const isAIFlowPage = false;

    const styles = isAIFlowPage ? aiFlowStyles : footerStyles;

    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    © 2025 CNAPS.AI
                </div>
                <div className={styles.right}>
                    <Link to={`${routes.terms}?type=${AgreementType.TERMS}`} className={styles.no_decoration_text}>
                        Terms of Use
                    </Link>
                    <span style={{margin: '0 8px'}}>|</span>
                    <Link to={`${routes.terms}?type=${AgreementType.PRIVACY}`} className={styles.no_decoration_text}>
                        Privacy Policy
                    </Link>
                    <span style={{margin: '0 8px'}}>|</span>
                    <Link to="#" className={styles.no_decoration_text} onClick={(e) => {
                        e.preventDefault();
                        onCookieSettingsClick();
                    }}>
                        Cookie Settings
                    </Link>
                    <span style={{margin: '0 8px'}}>|</span>
                    <Link to={routes.contactUs} className={styles.no_decoration_text}>
                        Contact CNAPS.AI
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default PageFooter;
