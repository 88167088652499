import React, {useEffect, useState} from "react";
import * as amplitude from "@amplitude/analytics-browser";
import styles from "./CookieConsent.module.scss";
import routes from "../routes";
import {AMPLITUDE_KEY, COOKIE_CONSENT_KEY} from "../config/defaultConfig";
import {CookieCategory} from "../interfaces/d";
import {reportCookieConsent, useAuth} from "../services/authService";

type ConsentType = {
    [CookieCategory.NECESSARY]: boolean;
    [CookieCategory.ANALYTICS]: boolean;
};

interface CookieConsentProps {
    isVisible: boolean;
    setIsVisible: (visible: boolean) => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({isVisible, setIsVisible}) => {
    const {userData, isLoggedIn} = useAuth();
    const [consent, setConsent] = useState<ConsentType>({
        necessary: true,
        analytics: false,
    });

    useEffect(() => {
        const savedConsent = localStorage.getItem(COOKIE_CONSENT_KEY);
        if (savedConsent) {
            const parsedConsent: ConsentType = JSON.parse(savedConsent);
            setConsent(parsedConsent);

            if (parsedConsent.analytics) {
                amplitude.init(AMPLITUDE_KEY, {
                    defaultTracking: true,
                });
            } else {
                if (amplitude.getSessionId()) {
                    amplitude.setOptOut(true);
                    amplitude.reset();
                }
            }
        } else {
            setIsVisible(true);
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(consent));
        setIsVisible(false);

        if (consent.analytics) {
            amplitude.init(AMPLITUDE_KEY, {
                defaultTracking: true,
            });
        } else {
            if (amplitude.getSessionId()) {
                amplitude.setOptOut(true);
                amplitude.reset();
            }
        }

        if (isLoggedIn && userData) {
            reportCookieConsent(userData);
        }
    };

    const handleReject = () => {
        const rejectedConsent = {necessary: true, analytics: false};
        localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(rejectedConsent));
        setConsent(rejectedConsent);
        setIsVisible(false);

        if (amplitude.getSessionId()) {
            amplitude.setOptOut(true);
            amplitude.reset();
        }

        if (isLoggedIn && userData) {
            reportCookieConsent(userData);
        }
    };

    if (!isVisible) return null;

    return (
        <div className={styles.cookieBanner}>
            <div className={styles.bannerContent}>
                <h3>🍪 We respect your privacy</h3>
                <p>
                    We use cookies to enhance your experience. By clicking <strong>Accept</strong>, you agree to the use
                    of selected cookies. If you click <strong>Reject</strong>, only essential cookies will be used.{" "}
                    <a href={`${routes.terms}?type=privacy`} target="_blank" rel="noopener noreferrer">
                        Learn more
                    </a>
                </p>

                <div className={styles.checkboxContainer}>
                    <label className={styles.disabled}>
                        <input type="checkbox" checked disabled/>
                        Necessary Cookies (Required)
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={consent.analytics}
                            onChange={() => setConsent({...consent, analytics: !consent.analytics})}
                        />
                        Analytics Cookies
                    </label>
                </div>

                <div className={styles.cookieButtonContainer}>
                    <button onClick={handleReject} className={`${styles.cookieButton} ${styles.cookieRejectButton}`}>
                        Reject
                    </button>
                    <button onClick={handleConsent} className={`${styles.cookieButton} ${styles.cookieAcceptButton}`}>
                        Accept Selected
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
