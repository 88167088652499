import React, {useEffect, useState} from 'react';
import styles from './PricingPage.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import routes from "../routes";
import {CheckoutSubscription, GetCustomerPortalURL, GetProductList, GetStripeKey} from "../services/paymentService";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {useAuth} from "../services/authService";
import {AgreementType, IProduct} from "../interfaces/d";

const PricingPage: React.FC = () => {
    const [pricingPlans, setPricingPlans] = useState<any[]>([]);
    const [customerPortalURL, setCustomerPortalURL] = useState<string>('');
    const [quantities, setQuantities] = useState<{ [priceId: string]: number }>({});
    const auth = useAuth();
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

    useEffect(() => {
        const fetchStripeKey = async () => {
            try {
                const stripeKey = await GetStripeKey();
                const stripeInstance = await loadStripe(stripeKey);
                setStripePromise(stripeInstance);
            } catch (error) {
                console.error('Error loading Stripe:', error);
            }
        };

        fetchStripeKey();

        const fetchPricingPlans = async () => {
            try {
                const products: IProduct[] = await GetProductList();
                const freePlan = {
                    id: 'free',
                    name: 'BETA',
                    description: '',
                    features: ['Unlimited # of Inference', 'Open-Sourced AI Models', 'Local GPU Only'],
                    prices: [0]
                };
                const enterprisePlan = {
                    id: 'enterprise',
                    name: 'Enterprise',
                    description: '',
                    features: ['Supports Internal Server', 'All AI Models', 'Customer\'s Proprietary AI Models', 'Local / Internal GPU Server APIs'],
                    prices: []
                };
                setPricingPlans([freePlan, ...products, enterprisePlan]);
            } catch (error) {
                console.error('Error fetching product list:', error);
            }
        };

        const getCustomerPortalURL = async () => {
            try {
                if (auth.isLoggedIn) {
                    setCustomerPortalURL(await GetCustomerPortalURL());
                } else {
                    setCustomerPortalURL('');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchPricingPlans();
        getCustomerPortalURL();
    }, [auth.isLoggedIn]);

    const handleGetStarted = () => {
        if (!auth.userData) {
            navigate(`/signin?redirect=${encodeURIComponent(routes.pricing)}`);
            return;
        }
    };

    const handleManageSubscription = async () => {
        if (customerPortalURL) {
            window.location.href = customerPortalURL;
        }
    };

    const handleCheckout = async (priceId: string, productId: string, quantity: number) => {
        if (!auth.isLoggedIn) {
            navigate(`/signin?redirect=${encodeURIComponent(routes.pricing)}`);
            return;
        }

        try {
            const callback = `${window.location.origin}/pricing`;
            const cancel_url = `${window.location.origin}/pricing`;
            const session = await CheckoutSubscription(priceId, productId, quantity, callback, cancel_url);
            if (!session.id) {
                throw new Error('Invalid session ID');
            }

            const stripe = await stripePromise;
            if (!stripe) {
                console.log('Stripe initialization failed');
                throw new Error('Stripe initialization failed');
            }

            await stripe.redirectToCheckout({sessionId: session.id});
        } catch (error) {
            alert('Unable to complete the checkout process. Please try again later.');
        }
    };

    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.pricing}>
                    <div className={styles.pricing_wrapper}>
                        <h1 className={styles.pricing_title}>Pricing Plans</h1>
                        <p className={styles.pricing_desc}>
                            Choose the plan that best suits you.
                        </p>

                        <div className={styles.pricing_cards}>
                            {pricingPlans.map((plan) => (
                                <div className={styles.pricing_card} key={plan.id}>
                                    <h2 className={styles.pricing_card_title}>{plan.name}</h2>
                                    {plan.prices && plan.prices.length > 0 ? (
                                        <p className={styles.pricing_card_price}>
                                            {plan.prices[0] === 0
                                                ? "Free"
                                                : `$${((plan.prices[0]?.unit_amount ?? 0) / 100).toFixed(2)} / Month`
                                            }
                                        </p>
                                    ) : (
                                        <p className={styles.pricing_card_price}>
                                            {plan.id === 'free' ? "Free" : "TBD"}
                                        </p>
                                    )}
                                    <ul className={styles.pricing_card_list}>
                                        {plan.features.map((feature: string, index: number) => (
                                            <li key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                    {/* Check user's current plan */}
                                    {auth.userData?.subscription.product_name === plan.id ? (
                                        plan.id === 'free' || plan.id === 'enterprise' ? (
                                            <span className={styles.current_plan_button}>Current Plan</span>
                                        ) : (
                                            <>
                                                <span className={styles.current_plan_button}>Current Plan</span>
                                                <button
                                                    className={styles.manage_subscription_button}
                                                    onClick={handleManageSubscription}
                                                >
                                                    Manage Subscription
                                                </button>
                                            </>
                                        )
                                    ) : (
                                        plan.id === 'free' ? (
                                            <button
                                                className={styles.pricing_card_button}
                                                onClick={handleGetStarted}
                                            >
                                                Get Started
                                            </button>
                                        ) : plan.prices && plan.prices.length > 0 ? (
                                            <div className={styles.quantity_container}>
                                                <input
                                                    type="number"
                                                    min="1"
                                                    value={quantities[plan.prices[0].id] || 1}
                                                    onChange={(e) => {
                                                        const value = parseInt(e.target.value, 10);
                                                        setQuantities({
                                                            ...quantities,
                                                            [plan.prices[0].id]: value
                                                        });
                                                    }}
                                                    className={styles.quantity_input}
                                                />
                                                <button
                                                    className={styles.pricing_card_button}
                                                    onClick={() =>
                                                        handleCheckout(
                                                            plan.prices[0].id,
                                                            plan.id,
                                                            quantities[plan.prices[0].id] || 1
                                                        )
                                                    }
                                                >
                                                    Buy Now
                                                </button>
                                            </div>
                                        ) : (
                                            <Link className={styles.pricing_card_button} to={routes.contactUs}>
                                                Contact Us
                                            </Link>
                                        )
                                    )}
                                </div>
                            ))}
                        </div>

                        {/* Disclaimer Section */}
                        <div className={styles.disclaimer}>
                            <h3 className={styles.disclaimer_title}>Key Information</h3>
                            <ul className={styles.disclaimer_list}>
                                <li>
                                    <strong>Billing Cycle:</strong> All paid plans are billed monthly via Stripe.
                                    Payment is processed securely by Stripe.
                                </li>
                                <li>
                                    <strong>Taxes:</strong> Taxes are not included in the prices shown. The final amount
                                    may vary depending on your location's tax laws and will be calculated at checkout.
                                </li>
                                <li>
                                    <strong>Subscription Renewal &amp; Cancellation:</strong> You can manage or cancel
                                    your subscription anytime through the Manage Subscription Button.
                                </li>
                                <li>
                                    <strong>Refund Policy:</strong> If you have not used the service after purchase, you
                                    may request a refund via our{' '}
                                    <Link to={routes.contactUs}>Contact Us</Link> page.
                                </li>
                                <li>
                                    By subscribing, you agree to our{' '}
                                    <a
                                        href={`${routes.terms}?type=${AgreementType.TERMS}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href={`${routes.terms}?type=${AgreementType.PRIVACY}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>.
                                </li>
                            </ul>
                        </div>
                        {/* End of Disclaimer Section */}

                    </div>
                </div>
            </div>
        </main>
    );
};

export default PricingPage;
