import {AgreementType} from "../interfaces/d";

export const APP_SERVICE_PORT = process.env.PORT || 8008;
export const APP_PROXY_PORT = 9001;

export const APP_BASE_URL = '';
export const APP_BASE_API_URL = `${APP_BASE_URL}/api/v1`;
export const APPLICATION_EXECUTE_PREFIX = 'cnapsai-aiflowstudio://?';
export const GoogleClientID = "957915589333-a0vl7dc7tsv4qqi1hf5aq4sni1bqs7h3.apps.googleusercontent.com";

export const API_URLS = {
    GoogleSignIn: APP_BASE_API_URL + '/auth/signin/google',
    Ping: APP_BASE_API_URL + '/auth/ping',
    SignOut: APP_BASE_API_URL + '/auth/signout',
    SISRModelProcess: APP_BASE_API_URL + '/model/process/sisr',
    SISRModelProcessPolling: (transaction_id: string) => {
        return `${APP_BASE_API_URL}/poll/${transaction_id}`;
    },
    SISRModelProcessResult: (transaction_id: string) => {
        return `${APP_BASE_API_URL}/result/${transaction_id}`;
    },
    SendEmail: APP_BASE_API_URL + '/email',
    Models: APP_BASE_API_URL + '/model',
    FlowNodeList: APP_BASE_API_URL + '/flow/node/list',
    UploadFlow: APP_BASE_API_URL + '/flow/upload',
    LoadFlow: (flow_id: string) => {
        return `${APP_BASE_API_URL}/flow/load/${flow_id}`
    },
    RenameFlow: (flow_id: string) => {
        return `${APP_BASE_API_URL}/flow/rename/${flow_id}`
    },
    TrashFlow: (flow_id: string) => {
        return `${APP_BASE_API_URL}/flow/trash/${flow_id}`
    },
    RestoreFlow: (flow_id: string) => {
        return `${APP_BASE_API_URL}/flow/restore/${flow_id}`
    },
    DeleteFlow: (flow_id: string) => {
        return `${APP_BASE_API_URL}/flow/delete/${flow_id}`
    },
    LoadFlowList: `${APP_BASE_API_URL}/flow/list`,
    FlowImageChecksum: (image_checksum: string) => {
        return `${APP_BASE_API_URL}/image/exist?imageId=${image_checksum}`
    },
    FlowImageUpload: APP_BASE_API_URL + '/image/upload',
    FlowPolling: (flow_id: string) => {
        return `${APP_BASE_API_URL}/flow/poll/${flow_id}`
    },
    UserDashboard: APP_BASE_API_URL + '/user/dashboard',
    UserFavoriteModels: (page_number: number) => {
        if (!page_number) page_number = 1;
        return `${APP_BASE_API_URL}/user/favorites?page=${page_number}`;
    },
    UserTransactions: (page_number: number) => {
        if (!page_number) page_number = 1;
        return `${APP_BASE_API_URL}/user/transactions?page=${page_number}`;
    },
    TransactionDownload: (transaction_id: string) => {
        return `${APP_BASE_API_URL}/result/${transaction_id}/download`;
    },
    FavoriteModelToggle: `${APP_BASE_API_URL}/user/favorite/toggle`,
    UserContactMessage: `${APP_BASE_API_URL}/contact`,
    ProfileEdit: `${APP_BASE_API_URL}/user/profile`,
    Transactions: (page_number: number) => {
        if (!page_number) {
            page_number = 1;
        }
        return `${APP_BASE_API_URL}/user/transactions?page=${page_number}`
    },
    CheckoutSubscription: `${APP_BASE_API_URL}/payment/checkout`,
    DownloadData: `${APP_BASE_API_URL}/setting/download`,
    ProductList: `${APP_BASE_API_URL}/product/list`,
    CustomerPortalURL: `${APP_BASE_API_URL}/payment/customer/portal`,
    TermsAgreement: (type: AgreementType) => {
        return `${APP_BASE_API_URL}/user/agreement/${type}`;
    },
    Terms: (type: AgreementType) => {
        return `${APP_BASE_API_URL}/data/agreement/${type}`;
    },
    GetStripeKey: `${APP_BASE_API_URL}/setting/stripe/publishable`
};

export const GENERATE_POLLING_INTERVAL_MS = 1000;
export const COOKIE_CONSENT_KEY = "cookie_consent";

export const DEFAULT_APP_CONFIG = {
    MAX_SELECTED_MODELS: 3,
};

export const CATEGORY_ICON_IMAGE: { [category: string]: string } = {
    'SISR': require('../asset/icon_model_sr.svg').default,
    'GenAI-Txt2Img': require('../asset/icon_model_gr.svg').default,
    'Deblur': require('../asset/icon_model_ds.svg').default,
}

export const IDLE_SECONDS_BEFORE_FLOW_UPLOAD = 3; // seconds
export const FLOW_DATA_POLLING_INTERVAL = 1;

export const AMPLITUDE_KEY = 'd18f044a1e74b03f65ff6b52c6373257';

export const ErrorCodes = {
    SESSION_EXPIRED: 7001,
    UNAUTHORIZED: 7001,
    VALIDATION_ERROR: 2002,
} as const;
export type ErrorCode = typeof ErrorCodes[keyof typeof ErrorCodes];
