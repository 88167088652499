import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import styles from "./TermsAgreement.module.scss";
import {AgreementTerms} from "../services/termsService";
import {AgreementType} from "../interfaces/d";
import {useAuth} from "../services/authService";
import routes from "../routes";

const TermsAgreementPage: React.FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
    const [privacyAgreed, setPrivacyAgreed] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (!auth.isLoggedIn) {
            navigate(routes.signIn);
        }
    }, []);

    const handleAgree = async () => {
        if (!termsAgreed || !privacyAgreed) {
            alert("You must agree to both the Terms of Use and the Privacy Policy to continue.");
            return;
        }
        setIsSubmitting(true);
        try {
            await AgreementTerms(AgreementType.TERMS);
            await AgreementTerms(AgreementType.PRIVACY);
            navigate(-1);
        } catch (error) {
            console.error("Error agreeing to terms:", error);
            alert("An error occurred while processing your agreement. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.intro}>
                    <h2>Welcome to CNAPS.AI</h2>
                    <p>
                        To provide you with the best experience and ensure compliance with legal requirements,
                        we require you to review and agree to our Terms of Use and Privacy Policy.
                    </p>
                    <p>
                        Please take a moment to read through the policies by clicking the links below. Your consent
                        is required to continue using our service.
                    </p>
                </div>
                <div className={styles.content}>
                    <p>
                        Review our{" "}
                        <a
                            href={`/terms?type=${AgreementType.TERMS}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                            href={`/terms?type=${AgreementType.PRIVACY}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>{" "}
                        before continuing.
                    </p>
                    <div className={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            id="agreeTerms"
                            checked={termsAgreed}
                            onChange={() => setTermsAgreed(!termsAgreed)}
                        />
                        <label htmlFor="agreeTerms">I agree to the Terms of Use.</label>
                    </div>
                    <div className={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            id="agreePrivacy"
                            checked={privacyAgreed}
                            onChange={() => setPrivacyAgreed(!privacyAgreed)}
                        />
                        <label htmlFor="agreePrivacy">I agree to the Privacy Policy.</label>
                    </div>
                </div>
                <button
                    className={styles.agreeButton}
                    onClick={handleAgree}
                    disabled={!termsAgreed || !privacyAgreed || isSubmitting}
                >
                    {isSubmitting ? "Processing..." : "Agree & Continue"}
                </button>
            </div>
        </div>
    );
};

export default TermsAgreementPage;
