import React, {useEffect, useState} from 'react';
import styles from './DownloadPage.module.scss';
import {IDownloadData} from '../interfaces/d';
import {DownloadData} from '../services/downloadService';
import {useAuth} from "../services/authService";
import {useNavigate} from "react-router-dom";
import routes from "../routes";

const DownloadPage: React.FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [data, setData] = useState<IDownloadData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await DownloadData();
                setData(response);
            } catch (error) {
                console.error("Failed to fetch download data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.about_item_container}>
                    <div className={styles.page_title}>
                        System requirements for AI Flow Studio
                    </div>

                    {loading ? (
                        <p>Loading...</p>
                    ) : data ? (
                        <>
                            <div className={styles.version_info}>
                                Version {data.version} • Last updated: {data.last_updated_date}
                            </div>

                            <div className={styles.requirements_section}>
                                <table className={styles.requirements_table}>
                                    <tbody>
                                    <tr>
                                        <td><strong>CPU</strong></td>
                                        <td>{data.requirements.cpu}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>System Memory</strong></td>
                                        <td>{data.requirements.memory}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>AI Processor</strong></td>
                                        <td>{data.requirements.ai_processor}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Disk Space</strong></td>
                                        <td>{data.requirements.disk_space}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={styles.download_buttons}>
                                {data.platforms.map((platform) => (
                                    <button
                                        key={platform.name}
                                        className={styles.download_btn}
                                        onClick={() => {

                                            if (platform.download_url && auth.userData) {
                                                window.location.href = platform.download_url;
                                            } else {
                                                navigate(`${routes.signIn}?redirect=${routes.download}`);
                                            }
                                        }}
                                        disabled={!platform.download_url}
                                    >
                                        <svg className={styles.download_icon} xmlns="http://www.w3.org/2000/svg"
                                             width="16"
                                             height="16" viewBox="0 0 24 24">
                                            <path fill="white" d="M12 16l4-5h-3V4h-2v7H8l4 5zm-9 3h18v2H3v-2z"/>
                                        </svg>
                                        Download for {platform.name} {platform.download_url ? "" : "(Coming Soon)"}
                                    </button>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p>Error loading data</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;
