import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {APISupremumSignInUsingGoogleCredential, useAuth} from '../services/authService';

import header_styles from './PageHeader.module.scss';
import ai_flow_styles from './PageHeaderAIFlow.module.scss';
import SymbolImage from '../asset/logo_symbol.svg';
import routes from "../routes";
import {AuthButton} from "./AuthButton";

const menu_items = [
    // {name: 'AI Flow', url: routes.AIFlow},
    {name: 'Download', url: routes.download},
    {name: 'Pricing', url: routes.pricing},
    // {name: 'API Docs', url: routes.apiDocs},
    {name: 'About', url: routes.about}
];

const PageHeader = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    
    // Disable AI Flow Web
    // const location = useLocation();
    // const is_ai_flow_page = location.pathname.startsWith(routes.AIFlow);
    const is_ai_flow_page = false;

    const styles = is_ai_flow_page ? ai_flow_styles : header_styles;

    const [show_desktop_message, set_show_desktop_message] = useState(false);
    const [menu_open, set_menu_open] = useState(false);

    useEffect(() => {
        const user_agent = navigator.userAgent.toLowerCase();
        const mobile_regex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

        if (mobile_regex.test(user_agent)) {
            set_show_desktop_message(true);
        }

        const timer = setTimeout(() => {
            set_show_desktop_message(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const toggle_menu = () => {
        set_menu_open(!menu_open);
    };

    const close_menu = () => {
        set_menu_open(false);
    };

    const handleSignOut = async () => {
        await auth.signOut(true);
    };

    // Google SignIn Success - mobile
    const handleGoogleSignInMobile = async (credentialResponse: any) => {
        if (!credentialResponse) {
            console.error('Login failed');
            return;
        }
        try {
            const userData = await APISupremumSignInUsingGoogleCredential(credentialResponse);
            auth.signIn(userData);
            close_menu();
            if (!userData.agreement_status.is_term_of_use_agreed || !userData.agreement_status.is_privacy_policy_agreed) {
                navigate(routes.termsAgreement);
            }
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    // Google SignIn Success - desktop
    const handleGoogleSignInDesktop = async (credentialResponse: any) => {
        if (!credentialResponse) {
            console.error('Login failed');
            return;
        }
        try {
            const userData = await APISupremumSignInUsingGoogleCredential(credentialResponse);
            auth.signIn(userData);

            if (!userData.agreement_status.is_term_of_use_agreed || !userData.agreement_status.is_privacy_policy_agreed) {
                navigate(routes.termsAgreement);
            }
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    return (
        <header className={styles.header}>
            {show_desktop_message && (
                <div className={styles.desktop_message}>
                    Currently optimized for desktop view.
                </div>
            )}

            {is_ai_flow_page && (
                <div className={styles.persistent_message}>
                    The desktop application will be released soon, and only editing is available on the web.
                </div>
            )}

            <div className={styles.wrapper}>
                <Link to='/' className={styles.no_underline} onClick={close_menu}>
                    <div className={styles.logo}>
                        <div className={styles.logo_symbol}>
                            <img
                                src={SymbolImage}
                                alt="symbol"
                                style={{height: "30px", verticalAlign: "middle"}}
                            />
                        </div>
                    </div>
                </Link>

                <button
                    className={`${styles.hamburger_button} ${menu_open ? styles.open : ''}`}
                    onClick={toggle_menu}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                <div className={`${styles.mobile_menu} ${menu_open ? styles.menu_open : ''}`}>
                    <ul className={styles.menu_list}>
                        {menu_items.map((item, index) => (
                            <li key={index} className={styles.menu_item}>
                                <Link
                                    className={styles.no_style_link}
                                    to={item.url}
                                    onClick={close_menu}
                                >
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <div className={styles.mobile_login_section}>
                        <AuthButton
                            auth={auth}
                            onSuccess={handleGoogleSignInMobile}
                            onError={() => console.log('Login failed')}
                            onSignOut={() => {
                                auth.signOut(true);
                                close_menu();
                            }}
                            signOutClassName={styles.login_signout}
                        />
                    </div>
                </div>

                <div className={styles.menu}>
                    <ul className={styles.menu_list}>
                        {menu_items.map((item, index) => (
                            <li key={index} className={styles.menu_item}>
                                <Link className={styles.no_style_link} to={item.url}>
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={styles.login}>
                    <AuthButton
                        auth={auth}
                        onSuccess={handleGoogleSignInDesktop}
                        onError={() => console.log('Login failed')}
                        onSignOut={handleSignOut}
                        signOutClassName={styles.login_signout}
                    />
                </div>
            </div>
        </header>
    );
};

export default PageHeader;
