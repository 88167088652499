import {API_URLS} from "../config/defaultConfig";
import {IAPIResponse, IDownloadData} from "../interfaces/d";
import ApiClient from "./apiClient";

export const DownloadData = async (): Promise<IDownloadData> => {
    try {
        const response = await ApiClient.get(API_URLS.DownloadData);
        const data: IAPIResponse<IDownloadData> = response.data;
        return data.data;
    } catch (error) {
        console.error("Error fetching download requirements:", error);
        throw error;
    }
};
