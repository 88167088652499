import {API_URLS} from "../config/defaultConfig";
import {
    ErrorCode,
    IAPIResponse,
    ICustomerPortalURL,
    IProduct,
    IStripeCheckoutSession,
    IStripePublicKey
} from "../interfaces/d";
import ApiClient from "./apiClient";

export const CheckoutSubscription = async (
    priceId: string,
    productId: string,
    quantity: number,
    callback: string,
    cancel_url: string
) => {
    try {
        const response = await ApiClient.post(API_URLS.CheckoutSubscription, {
            callback,
            cancel_url,
            price_id: priceId,
            product_id: productId,
            quantity,
        });
        const data: IAPIResponse<IStripeCheckoutSession> = response.data;
        return {
            id: data.data.session_id
        }
    } catch (error) {
        throw error;
    }
};

export const GetProductList = async (): Promise<IProduct[]> => {
    try {
        const response = await ApiClient.get(API_URLS.ProductList);
        const data: IAPIResponse<{ products: IProduct[] }> = response.data;

        if (data.result === ErrorCode.NoError && (!data.error || data.error.code === 0)) {
            const products: IProduct[] = data.data.products;
            return products;
        } else {
            throw new Error(data.error?.msg || 'Unknown error');
        }
    } catch (error) {
        console.error('error get product list:', error);
        throw error;
    }
};

export const GetCustomerPortalURL = async (): Promise<string> => {
    try {
        const response = await ApiClient.post(API_URLS.CustomerPortalURL, {
            callback: window.location.href
        });
        const data: IAPIResponse<ICustomerPortalURL> = response.data;

        if (data.result === ErrorCode.NoError && (!data.error || data.error.code === 0)) {
            return data.data.url;
        } else {
            throw new Error(data.error?.msg || 'Unknown error');
        }
    } catch (error) {
        throw error;
    }
};

export const GetStripeKey = async (): Promise<string> => {
    try {
        const response = await ApiClient.get(API_URLS.GetStripeKey);
        const data: IAPIResponse<IStripePublicKey> = response.data;
        return data.data.stripe_publishable_key;
    } catch (error) {
        console.error("Error fetching Stripe key:", error);
        throw error;
    }
}