const routes = {
    appAuth: '/app_auth',
    home: '/',
    download: '/download',
    signIn: '/signin',
    signUp: '/signup',
    modelList: '/models',
    modelComparison: '/model-comparison',
    evaluations: '/evaluations',
    datasets: '/datasets',
    apiDocs: '/api-docs',
    about: '/about',
    contactUs: '/about#contact_us',
    dashboard: '/dashboard',
    "generative-ai": '/generative-ai',
    "AIFlow": '/ai-flow',
    pricing: '/pricing',
    healthCheck: '/health',
    terms: '/terms',
    termsAgreement: '/termsAgreement',
};

export default routes;
