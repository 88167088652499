import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GoogleClientID} from "./config/defaultConfig";
import routes from './routes';

import {AuthProvider, CheckSession, useAuth} from './services/authService';

import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import CookieConsent from "./components/CookieConsent";

import IndexPage from './views/IndexPage';
import SignInPage from './views/SignInPage';
import GenerativeAIPage from "./views/GenerativeAIPage";
import AboutPageEmailForm from "./views/AboutPageEmailForm";
import PricingPage from "./views/PricingPage";
import DownloadPage from "./views/DownloadPage";
import AppAuth from "./views/AppAuth";
import AgreementContentsPage from "./views/AgreementContentsPage";
import TermsAgreementPage from "./views/TermsAgreementPage";

import './App.css';
import SessionChecker from "./components/SessionChecker";


const App = () => {
    const auth = useAuth();
    const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(false);


    useEffect(() => {
        const checkUserSession = async () => {
            if (auth.userData) {
                const currentTime = Date.now();
                const expireTime = auth.userData.session.expire_at;

                if (expireTime && currentTime >= expireTime) {
                    console.warn('Session has expired.');
                    await auth.signOut(false);
                } else {
                    // Call CheckSession to verify with the server
                    const sessionValid = await CheckSession(auth);
                    if (!sessionValid) {
                        console.warn('Server indicates session is invalid.');
                        await auth.signOut(false);
                    }
                }
            }
        };

        checkUserSession();
    }, [auth]);

    return (
        <GoogleOAuthProvider clientId={GoogleClientID}>
            <AuthProvider>
                <Router>
                    <SessionChecker/>
                    <Routes>
                        {/* app_auth */}
                        <Route path={routes.appAuth} element={<AppAuth/>}/>

                        {/* normal */}
                        <Route path="*" element={
                            <>
                                <PageHeader/>
                                <Routes>
                                    <Route path={routes.home} element={<IndexPage/>}/>
                                    <Route path={routes.signIn} element={<SignInPage/>}/>
                                    {/*<Route path={routes.modelList} element={<ModelListPage/>}/>*/}
                                    {/*<Route path={routes.modelComparison} element={<ModelComparisonPage/>}/>*/}
                                    {/*<Route path={routes.dashboard} element={*/}
                                    {/*    <ProtectedRoute>*/}
                                    {/*        <PageDashboard/>*/}
                                    {/*    </ProtectedRoute>*/}
                                    {/*}/>*/}
                                    <Route path={routes.about} element={<AboutPageEmailForm/>}/>
                                    <Route path={routes["generative-ai"]} element={<GenerativeAIPage/>}/>
                                    {/*<Route path={routes.datasets} element={<ComingSoonPage/>}/>*/}
                                    {/*<Route path={routes.apiDocs} element={<ComingSoonPage/>}/>*/}
                                    {/*<Route path={routes.evaluations} element={<EvaluationsPage/>}/>*/}
                                    {/*<Route path={routes.AIFlow} element={<AIFlow/>}/>*/}
                                    {/*<Route path={`${routes.AIFlow}/:uuid`} element={<AIFlow/>}/>*/}
                                    {/*<Route path={`${routes.AIFlow}/copy/:uuid`} element={<AIFlow isCopy={true}/>}/>*/}
                                    <Route path={routes.pricing} element={<PricingPage/>}/>
                                    <Route path={routes.download} element={<DownloadPage/>}/>
                                    <Route path={routes.terms} element={<AgreementContentsPage/>}/>
                                    <Route path={routes.termsAgreement} element={<TermsAgreementPage/>}/>
                                </Routes>
                                <PageFooter onCookieSettingsClick={() => setIsCookieConsentVisible(true)}/>
                                <CookieConsent isVisible={isCookieConsentVisible}
                                               setIsVisible={setIsCookieConsentVisible}/>
                            </>
                        }/>
                    </Routes>
                </Router>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
};

export default App;
