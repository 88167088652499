import {API_URLS} from "../config/defaultConfig";
import {AgreementType, CookieCategory, IAPIResponse, IAPIResponseAgreementContents} from "../interfaces/d";
import ApiClient from "./apiClient";

const baseURL = process.env.REACT_APP_BASE_URL || window.location.origin;
export const GetTerms = async (
    type: AgreementType // terms, privacy
): Promise<IAPIResponseAgreementContents> => {
    try {
        const response = await ApiClient.get(API_URLS.Terms(type));
        const data: IAPIResponse<IAPIResponseAgreementContents> = response.data;

        return {
            url: `${baseURL}${data.data.url}`,
            version: data.data.version,
        };
    } catch (error) {
        console.error("Error fetching terms:", error);
        throw error;
    }
};

export const AgreementTerms = async (
    type: AgreementType,            // terms, privacy, cookie
    categories?: CookieCategory[],  // only for cookie agreement
) => {
    try {
        const requestData = type === AgreementType.COOKIE && categories ? {categories: categories} : {};
        const response = await ApiClient.post(API_URLS.TermsAgreement(type), requestData);
        const data: IAPIResponse<{}> = response.data;

        return data.data;
    } catch (error) {
        throw error;
    }
};
