import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from "./AgreementContentsPage.module.scss";
import {GetTerms} from "../services/termsService";
import {AgreementType} from "../interfaces/d";

const AgreementContentsPage = () => {
    const location = useLocation();
    const [termsMarkdown, setTermsMarkdown] = useState<string>("");
    const [error, setError] = useState<string>("");

    const searchParams = new URLSearchParams(location.search);
    const type_from_query = searchParams.get("type")
    const queryType = type_from_query && Object.values(AgreementType).includes(type_from_query as AgreementType)
        ? type_from_query as AgreementType
        : AgreementType.TERMS

    useEffect(() => {
        const fetchTermsContent = async () => {
            try {
                const response = await GetTerms(queryType);

                const res = await fetch(response.url);
                const markdownText = await res.text();

                setTermsMarkdown(markdownText);
            } catch (err) {
                console.error("Failed to load agreement content:", err);
                setError("Failed to load agreement content.");
            }
        };
        fetchTermsContent();
    }, [queryType]);

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }

    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.termsContent}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {termsMarkdown}
                    </ReactMarkdown>
                </div>
            </div>
        </main>
    );
};

export default AgreementContentsPage;
